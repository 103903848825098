import { Component, Input, NgZone, OnInit, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  private sanitaizer = inject(DomSanitizer);
  vodafoneWeb = 'https://www.vodafone.es';
  urlDispositivos = 'https://www.vodafone.es/c/tienda-online/particulares/catalogo/';
  title = 'vodafoneApp'; //WEB TITLE

  @Input() adobeID?: string | null = '' // el codigo de adobe que recibe el iframe

  @Input() instance?: string | null = "1"; //ACTUAL ACTION INSTANCE
  //IT IS USED TO KNOW IN WHICH OF THE TRHEE PATHS WE ARE.
  // -> 1 -> VODAFONE MAIN PAGE, INTRODUCES TO VFMAGICA
  // -> 2 -> VODAFONE MAGICA CHAT PREVIEW
  // -> 3 -> VODAFONE MAGICA CONVERSATIONAL INSTANCE

  firstChat = true; //SPINNER FOR CHAT BEGINING - HTML LINE 56
  currentChatPath = ""; //TO DETECT IF ITS FIRST INTERACTION, BECAUSE FIRST INPUT INST GLOBAL, ITS DUPLICATED
  //THE FIRST INPUT BELONGS TO INSTANCE 2, THE CHAT INPUT BELONGS TO INSTANCE 3
  //IT IS NOT AN INPUT MODULE, IT IS AN INDIVIDUAL, CLONED INPUT
  session_id = ""; //SESSION ID FOR CORRECT API CONTEXT CONVERSATION
  source: string = '';

  //--CHAT BOX DATE VARIABLES--
  date: Date | any;
  currentHour = undefined;
  currentMinute = undefined;
  //---------------------------

  //----CHAT BOX VARIABLES----
  chatLog: [string, Date | any, any, string, any?, any?, boolean?, any?, any?, any?][] = [];
  resultAPI: string[] = [];
  recordSearch: string[] = [];
  //ARRAY FOR CHAT CONVERSATION INSTANCE
  //[chatMessage, date, this.iamessage, "DEFAULT", this.iaoptions]);

  inputDisabled: boolean = true; //BOOLEAN TO DISABLE INPUT TILL IA RESPONSE IS PRINTED
  finalOfChat: boolean = false; //BOOLEAN TO DISABLE INPUT WHEN FINAL

  timeoutreached: boolean = false;
  timeoutid: any = null;
  stopCountdown: boolean = false;
  secondsremaining: any = 10;
  timeoutmessages: string[] = ["La Inteligencia Artificial todavía no llega a ser igual de rápida que la mente humana, pero estoy trabajando en ello… ¡espera un momento por favor!", "Estamos trabajando en ser una Inteligencia Artifical más rápida, ¡espera un momento por favor!"];

  chatEntryInputValue: string = ''; //FIRST INPUT VALUE, FIRST INTERACTION WITH CHAT
  chatInputValue: string = ''; //INPUT VALUE FOR CHAT INTERACTIONS
  iamessage: string | any; //MESSAGE OF THE IA OUTPUT
  iaoptions: string[] = []; //MESSAGE OPTIONS ARRAY OF THE IA OUTPUTS
  tarifaCardNames: string[] | null = []; //TARIFA CARD NAME TO SHOW CARD PNG
  tarifaCardPrices: string[] | null = []; //TARIFA CARD PRICE TO SHOW CARD PNG
  haveDate: boolean = false;
  tarifaSeleccionada: string = '';

  url_servicio:string = environment.apiOrquestador; //url del orquestador //para local usar apiOrquestadorLocal
  url_feedback:string = environment.apiFeedback; //url del feedback
  modalSwitch: boolean = false; //variable para controlar si el popup esta abierto o cerrado
  showWeb: boolean = false;
  urlWeb: any = '';
  onlyMoreBtn: boolean = false;
  heightIframe: any;
  widthIframe: any;
  wantKnowMore: boolean = false; //variable para guardar si el usuario quiere saber más o no
  wantKnowMoreQuestionDone: boolean = false; //variable para guardar si el usuario ha relizado ya la pregunta de quiere saber más o no
  tarifaCardNameSelected: string = ''; // variable para almacenar el nombre de la tarifa seleccionada
  focusId:string = ''
  chatLogId: [any?] = [];
  sourceLog: [any?, any?] = [];
  isPortability: boolean = false;
  searchTarifas: any = [];
  showCarrusel: boolean = false;
  tvSelected: string = 'hbo_img'
  tvList: string[] | null = []; // variable para guardar el listado de las tv
  showTarifas: boolean = false //variable para saber si se han pintado las tarifas
  firstCall: boolean = true
  phone1: string | null | undefined
  phone2: string | null | undefined
  withPhone:boolean = false
  userPhone: string = ''
  errorPhone: boolean = true
  askForPhone:boolean = false
  acceptPrivacyPolicy = false;
  acceptCommercialCommunications = false;
  disabledSendBtn: boolean = true
  isCallInIframe:boolean = false;
  contador: number = 0;
  intervalId: any;

  /**
   * constructor
   * @param ngZone 
   */
  constructor(private ngZone: NgZone, private route: ActivatedRoute) {
    this.session_id = "";
    this.urlWeb = this.sanitaizer.bypassSecurityTrustResourceUrl(this.vodafoneWeb)
  }

  ngOnInit(): void {
    // Agregar el listener al cargar el componente
    //window.addEventListener('beforeunload', this.clearSessionStorage);
    this.doAutoScroll() //AUTO SCROLL FUNCTION WHEN PAGE IS LOADED
    //this.instance = '1';
    // Obtener el valor del parámetro "instance" de la URL
    this.route.queryParams.subscribe(params => {
      const adobeIDAux = params['adobe_id'];
      const instanceAux = params['instance'];
      const phone = params['phone'];
      const isCallInIframe = params['iframe'];
      //console.log('adobeID:', adobeIDAux);
      //console.log('instance:', instanceAux);
      //console.log('phone:', phone);
      //console.log('isCallInIframe:', isCallInIframe);
      if (adobeIDAux){
        this.adobeID = adobeIDAux
      }
      if (instanceAux){
        this.instance = instanceAux
      }
      if(phone) {
        this.withPhone = true
      }
      if(isCallInIframe) {
        this.isCallInIframe = true
        this.loadFromStorage();
      }
      if(this.instance=='3' && this.chatLog.length<1){
        this.instance='2'
      }
      console.log('adobeID:', this.adobeID);
      console.log('instance:', this.instance);
      console.log('withPhone:', this.withPhone);
      console.log('isCallInIframe:', this.isCallInIframe);
      console.log('');
      console.log('Inicio contador tiempo');
      this.contador = 0;
      this.intervalId = setInterval(() => {
        this.contador++;
        if (this.contador >= 30) { // 30 minutos
          this.limpiarSessionStorage();
          clearInterval(this.intervalId);
          console.log('Fin contador tiempo 1 min, limpiamos sesionstorage');
        }
      }, 60000); // 1 minuto
      console.log('');
    });
  }
  
  clearSessionStorage() {
    sessionStorage.clear();
  }

  ngOnDestroy(): void {
    //window.removeEventListener('beforeunload', this.clearSessionStorage);
    clearInterval(this.intervalId);
  }

  limpiarSessionStorage(): void {
    sessionStorage.clear();
  }
  
  loadFromStorage() {
    // Obtener el valor guardado en el almacenamiento local
    const chatLogsessionStorage = sessionStorage.getItem('chatLog');
    const sourceLogsessionStorage = sessionStorage.getItem('sourceLog');

    let instanceAux = '2'

    if (chatLogsessionStorage && sourceLogsessionStorage) {
      // Convertir la cadena JSON de vuelta a un objeto JavaScript
      this.chatLog = JSON.parse(chatLogsessionStorage);
      this.sourceLog = JSON.parse(sourceLogsessionStorage);
      console.log('Objeto cargado del storage:', this.chatLog, this.sourceLog);
      instanceAux = '3'
    }
    console.log('instanceToSend: ', instanceAux)
    window.parent.postMessage('instance=' + instanceAux, '*');
  }

  //AUTO SCROLL FUNCTION
  doAutoScroll() {
    window.onload = function () {
      window.scrollTo({ top: 420, behavior: 'auto' });
      //420 IS, IN PX, THE HEIGHT OF THE VFMAGICA PRESENTATION BOX
      //IN THE FIRST INSTANCE, IT IS HIDDEN, USER MAY SCROLL UP TO SEE IT
    }
  }

/**
 * funcion que dice que pantalla hay que pintar, recibe el numero de la pantalla a pintar
 * 1-Patalla Inicio, donde esta el pop up y el boton de hablamos
 * 2-Pantalla intermedia, menu superior para volver, un texto y la caja de texto para que introduzcan la consulta
 * 3-Chat con IA (orquestador)
 * @param instance 
 */
  //INSTANCE CHANGER FUNCTION
  setInstance(instance: string) {
    this.instance = instance; //UPDATE INSTANCE    

    //comprobamos si es la primera vez que abre el chat con la IA o si ya hizo consultas, para mostrar una pantalla u otra
    if (this.currentChatPath == '' || this.chatLog.length == 0) { 
      this.currentChatPath = "firstInteraction";
    } else {
      this.instance == "3";
      this.currentChatPath = "secondInteraction";
    }
    //console.log('setInstance - currentChatPath: ',this.currentChatPath)

    //comprobamos que instancia (pantalla) hay que ejecutar
    if (this.instance == "3") {
      // impedimos que pueda escribir el usuario en la caja de texto
      this.inputDisabled = true;
      //llamamos al orquestador
      this.firstCallOrquestador();

      //this.makeWatsonHandShake();
    }
    else {
      //desactivamos el modal
      this.modalSwitch = false;
    }

  }

  /**
   * funcion que devuelve el tipo de la variable que recibe
   * @param value 
   * @returns 
   */
  checkTypeof(value: any) {
    //console.log('checkTypeof - ', value)
    return typeof(value);
  }

  /**
   * Funcion que llama al orquestador le envia la primera consulta que hace el usuario, le manda el texto que introduce el usuario
   */
  async firstCallOrquestador(){  
    console.log('')
    console.log('INICIO')
    console.log('Call Orquestador - enviamos: ', this.chatEntryInputValue)
    this.sourceLog = []
    //comprobamos si hay consultas pasadas, ya que esta funcion se va a ejecutar cuando nunca se ha hecho una consulta
    if (this.chatLog.length < 1 && this.currentChatPath.length > 0){      
      //inicializamos la fecha y hora
      this.date = new Date();
      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(this.chatEntryInputValue))
      console.log('url orquestador:', this.url_servicio + encodeURIComponent(this.chatEntryInputValue))
      
      this.ngZone.run(() => {
        //añadimos al array el valor que ha introducido el usuario en la caja de texto para consultar
        //para que salga como el usuario el valor debe ir al principio
        //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
        this.chatLog.push([this.chatEntryInputValue, this.date, '', "default", undefined, undefined, undefined, undefined]);
        this.chatLogId.push('vm_'+this.chatLog.length)
        //variable que habilita o bloquea la caa de texto para que el usuario no pueda escribir mientras esta esperando a que termine la consulta del orquestador
        this.finalOfChat = true;
      });

      //para consultar en local
      //const events = new EventSource('http://localhost:8000/sse?prompt=' + encodeURIComponent(this.chatEntryInputValue));
      //llamamos al orquestador y le pasamos en la url el texto a consultar
      let urlAUX = this.url_servicio + encodeURIComponent(this.chatEntryInputValue)
      if (this.adobeID && this.firstCall) {
        urlAUX += '&adobe_id='+ this.adobeID
        this.firstCall = false
      }
      const events = new EventSource(urlAUX);
      events.onmessage = (event) => {
        const parsedData = event as any;
        //variable donde se guarda la respuesta del orquestador
        let mensaje_stream = JSON.parse(parsedData.data).response.message;
        //console.log('CARUSSEL ASSISTANT:', JSON.parse(parsedData.data).response.carrusel)
        this.showCarrusel = JSON.parse(parsedData.data).response.carrusel == 'yes' ? true : false;
        //console.log('mensaje_stream' + mensaje_stream)
        //console.log('mensaje_stream typeof' + typeof mensaje_stream)
        let source = JSON.parse(parsedData.data).source;
        let question_id = JSON.parse(parsedData.data).question_id
        this.ngZone.run(() => {
          this.source = source;
          //console.log('source: ' + source)
          this.sourceLog.push([source, mensaje_stream])
        });

        //comprobamos si el id de la sesion esta a nulo
        if(JSON.parse(parsedData.data).session_id == null){
          //console.log("firstCallOrquestador - session_id: null");
        } else if(this.session_id == '') {
          this.ngZone.run(() => {
            this.session_id = JSON.parse(parsedData.data).session_id;
            //console.log("firstCallOrquestador - session_id: ", this.session_id);
          });
        }

        
        //comprobamos que la respuesta no es nula ni esta vacia y el source no es API
        if (JSON.parse(parsedData.data).response.message !== undefined && JSON.parse(parsedData.data).response.message != '' && source != 'API') {
          this.ngZone.run(() => {
            this.filterMessage(mensaje_stream, source, question_id)
          });
        }
        
        //comprobamos que la respuesta que trae un documento esta vacio
        if (JSON.parse(parsedData.data).response.document_url !== undefined) {
          console.log('Document_url: ', JSON.parse(parsedData.data).response.document_url)
          this.ngZone.run(() => {
            //añadimos al array el valor que devuelve el orquestador de la consulta
            //para que salga como el chatBot el valor debe ir en la 3 posicion
            //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
            this.chatLog.push(['', this.date, JSON.parse(parsedData.data).response.document_url, "default", undefined, undefined, undefined]);
            this.chatLogId.push('vm_'+this.chatLog.length)
          });
        }
        
        //comprobamos si es el último mensaje que devuelve el orquestador
        if (JSON.parse(parsedData.data).response.last_message !== undefined && JSON.parse(parsedData.data).response.last_message) {
          //console.log('firstCallOrquestador - mensaje_stream: ', this.chatLog[this.chatLog.length-1][2])
          console.log('FIN')
          //console.log('last_message: ', JSON.parse(parsedData.data).response.last_message)
          //cerramos la conexion con el orquestador
          events.close();
          
          this.ngZone.run(async () => {

            //console.log('firstCallOrquestador - chatLog length ', this.chatLog.length)
            console.log('Historial source ', this.sourceLog)
            console.log('Historial ', this.chatLog)
            console.log('')

            // funcion para mostrar los iconos del feecback cuando termina el orquestador
            this.checkShowFeedback();

            // comprobamos si estamos pintando las tarifas, si las pintamos bloqueamos al usuario para que no escriba, sino dejamos escribir al usuario
            if (this.showTarifas) {
              this.finalOfChat = true;
            } else {
              //variable que habilita o bloquea la caa de texto para que el usuario no pueda escribir mientras esta esperando a que termine la consulta del orquestador
              this.finalOfChat = false;
            }
            //indicamos que ya no es el primer chat que tiene el usuario con IA
            this.firstChat = false;
            //vaciamos la caja de texto para que el usuario pueda volver a escribir
            this.chatEntryInputValue = '';

            this.checkSource(JSON.parse(parsedData.data).response.last_message)
          });
        }
      };
    }
  }

  /**
   * funcion que filtra los mensajes recibidos por el orquestador, dependiendo de su contenido pintamos diferentes elementos
   * Tipos de contenidos:
   * '[' => botones
   * '##' => tarifas
   * 'Dispositivos' => dispositibos
   * otros => el resto de texto, comprueba si el texto tiene que seguir rellenando
   * @param message 
   */
  filterMessage(message: any, source :any, question_id: any) {
    //console.log('message', message)
    //comprobamos si el mensaje es un array de objetos
    if (message.includes("[")){
      //añadimos al array el valor que devuelve el orquestador de la consulta
      //para que salga como el chatBot el valor debe ir en la 3 posicion
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, JSON.parse(message), "default", undefined, undefined, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)  
      this.showTarifas = false    
      //console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
    } else if (message.includes('##') && message.includes('€') && !message.includes('##Dispositivos')){ //comprobamos si el mensaje trae tarifas a pintar
      this.showTarifas = true
      this.isPortability = message.includes('##PortabilidadSI') ? true: false;
      let aux = message.replace(/##tarifaCarrousel##(.*?)##/g, '').trim().replace(',','');
      if(message.includes('##Portabilidad')){
        let auxPortabilida = aux.replace(/##Portabilidad(.*?)##/g, '').trim().replace(',','');
        aux = auxPortabilida
      }
      //console.log('firstCallOrquestador - aux: ', aux)
      let indexEuro = aux.indexOf("€");
      //console.log('firstCallOrquestador - indexEuro: ', indexEuro)
      //console.log('firstCallOrquestador - aux length: ', aux.length)
      this.iamessage = aux.substring(0, indexEuro);
      //console.log('firstCallOrquestador - iamessage: ', this.iamessage)
      this.tarifaCardNames = this.getTarifaCardNames(message);
      //console.log('firstCallOrquestador - tarifaCardNames: ', this.tarifaCardNames)
      this.tarifaCardPrices = this.getTarifaCardPrices(message);
      //console.log('firstCallOrquestador - tarifaCardPrices: ', this.tarifaCardPrices)

      let imglist: string[] = [];
      // Remove white spaces and accent marks
      this.tarifaCardNames?.forEach((element) => {
        const tarifaNameNew = element.replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        imglist.push(tarifaNameNew)
        //console.log('callOrquestador - imglist elemento:', tarifaNameNew.toLowerCase())
      });
      //console.log('firstCallOrquestador - imglist:', imglist)
      
      //if (this.iamessage.length>0) {
      //añadimos al array el valor que devuelve el orquestador de la consulta
      //para que salga como el chatBot el valor debe ir en la 3 posicion
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios, lista de imagenes TV a pintar, question_id])
      this.chatLog.push(['', this.date, message, "default tarifaCard", this.iamessage, this.tarifaCardNames, false, this.tarifaCardPrices, this.tvList, question_id]);
      this.chatLogId.push('vm_'+this.chatLog.length)
      //console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
      //}

      //envio feedback cuando pintamos las tarifas
      let tarifasFeedback = this.getTarifasForFeedback(message);
      this.sendFeedbackCarruselNoResponse(question_id, message, tarifasFeedback)
    } else if (message.includes('##Dispositivos')) {
      this.showTarifas = false
      //console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
      let inicioIndice = message.indexOf('#');
      let primeraFrase = message.substring(0, inicioIndice);
      this.chatLog.push(['', this.date, primeraFrase, "default dispositivos",primeraFrase,'dispositivos',false, undefined]);
    } else if (message != '' && !message.includes('-1')) {
      this.showTarifas = false
      //añadimos al array el valor que devuelve el orquestador de la consulta
      //para que salga como el chatBot el valor debe ir en la 3 posicion      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      //escribir en la misma linea 
      if(this.chatLog.length >1 && this.chatLog[this.chatLog.length-1][0] == '' && this.chatLog[this.chatLog.length-1][3] != 'default dispositivos' && this.chatLog[this.chatLog.length-1][3] != 'default startBtn') {
        let aux_content = this.chatLog[this.chatLog.length-1][2]
        //console.log('aux_content ', aux_content)
        aux_content += message
        if(source == 'WATSON')
          this.chatLog[this.chatLog.length-1] = (['', this.date, aux_content, "default", 'feedback', question_id, false]);
        else 
          this.chatLog[this.chatLog.length-1] = (['', this.date, aux_content, "default"]);
      } else {
        //escribir en una nueva linea
        //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
        this.chatLog.push(['', this.date, message, "default",undefined,undefined,undefined]);
        this.chatLogId.push('vm_'+this.chatLog.length)
        console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
      }

      if (message.includes('Teléfono 1') || message.includes('teléfono 1') || message.includes('telefono 1')) {
        //guardamos el valor de los telefonos
        const parser = new DOMParser();
        const doc = parser.parseFromString(message, 'text/html');
    
        this.phone1 = doc.querySelector('ul li strong')?.textContent;
        this.phone2 = doc.querySelector('ul li:nth-child(2) strong')?.textContent;
        console.log('this.phone1',this.phone1)
        console.log('this.phone2',this.phone2)
      }
    }
  }

  /**
   * funcion que llama al orquestador para obtener la informacion, le manda el valor que introduce el usuario por pantalla
   * @param valueToSend 
   */
  async callOrquestador(valueToSend: string, printSendText: boolean, newCicle: boolean){
    if(valueToSend.length > 0) {
      this.sourceLog = []
      console.log('')
      console.log('INICIO')
      console.log('Call Orquestador - enviamos: ', valueToSend)

      //inicializamos la fecha y hora
      this.date = new Date();
      //variable donde guardamos el mesnaje del chatBot que mostraremos en el front
      this.iamessage = '';
      //variable donde vamos a guardar todas las opciones de tarifas que recibamos de la consulta
      this.iaoptions = [];
      //variable que oculta el boton de Lo quiero cuando esta en true
      this.onlyMoreBtn = false;

      this.ngZone.run(() => {
        //añadimos al array el valor que ha introducido el usuario en la caja de texto para consultar
        //para que salga como el usuario el valor debe ir al principio
        //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
        if (printSendText) {
          this.chatLog.push([valueToSend, this.date, '', "default", undefined, undefined, undefined]);
        } else {
          this.chatLog.push([valueToSend, this.date, '', "default", true, undefined, undefined]);
        }
        this.chatLogId.push('vm_'+this.chatLog.length)
        //variable que habilita o bloquea la caa de texto para que el usuario no pueda escribir mientras esta esperando a que termine la consulta del orquestador
        this.finalOfChat = true;
        //vaciamos la caja de texto para que el usuario pueda volver a escribir
        this.chatInputValue = '';
      });

      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id);
      console.log('url orquestador:', this.url_servicio + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      const events = new EventSource(this.url_servicio + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id);
      events.onmessage = (event) => {
        const parsedData = event as any;
        //variable donde se guarda la respuesta del orquestador
        let mensaje_stream = JSON.parse(parsedData.data).response.message;
        this.showCarrusel = JSON.parse(parsedData.data).response.carrusel == 'yes' ? true : false;
        //console.log('callOrquestador - mensaje_stream: ', JSON.parse(parsedData.data).response)
        let source = JSON.parse(parsedData.data).source;
        let question_id = JSON.parse(parsedData.data).question_id
        this.ngZone.run(() => {
          this.source = source;
          //console.log('source: ' + source)
          this.sourceLog.push([source, mensaje_stream])
        });
        //console.log('source' + source)
              
        //comprobamos que la respuesta no es nula ni esta vacia y el source no es API
        if (JSON.parse(parsedData.data).response.message !== undefined && JSON.parse(parsedData.data).response.message != '' && source != 'API') {
          this.ngZone.run(() => {            
            this.filterMessage(mensaje_stream, source, question_id)
          });
        }
        
        //comprobamos que la respuesta que trae un documento esta vacio
        if (JSON.parse(parsedData.data).response.document_url !== undefined) {
          console.log('document_url: ', JSON.parse(parsedData.data).response.document_url)
          this.ngZone.run(() => {
            //añadimos al array el valor que devuelve el orquestador de la consulta
            //para que salga como el chatBot el valor debe ir en la 3 posicion
            //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
            this.chatLog.push(['', this.date, JSON.parse(parsedData.data).response.document_url, "default", undefined, undefined, undefined]);
            this.chatLogId.push('vm_'+this.chatLog.length)
          });
        }
        
          //comprobamos si es el último mensaje que devuelve el orquestador
        if (JSON.parse(parsedData.data).response.last_message !== undefined && JSON.parse(parsedData.data).response.last_message) {
          //console.log('callOrquestador - mensaje_stream: ', this.chatLog[this.chatLog.length-1][2])
          console.log('last_message: ', JSON.parse(parsedData.data).response.last_message)
          //console.log('CARUSSEL ASSISTANT:', JSON.parse(parsedData.data).response.carrusel)
          console.log('carrusel: ', this.showCarrusel)
          events.close();
          this.ngZone.run(async () => {

            // comprobamos si estamos pintando las tarifas, si las pintamos bloqueamos al usuario para que no escriba, sino dejamos escribir al usuario
            if (this.showTarifas && !newCicle && !this.wantKnowMore) {
              this.finalOfChat = true;
            } else {
              //variable que habilita o bloquea la caa de texto para que el usuario no pueda escribir mientras esta esperando a que termine la consulta del orquestador
              this.finalOfChat = false;
            }
            //vaciamos la caja de texto para que el usuario pueda volver a escribir
            this.chatEntryInputValue = '';

            console.log('showTarifas', this.showTarifas)
            console.log('newCicle', newCicle)
            console.log('wantKnowMore', this.wantKnowMore)
            console.log('wantKnowMoreQuestionDone', this.wantKnowMoreQuestionDone)
            if (this.wantKnowMore && !this.wantKnowMoreQuestionDone) {
              this.wantKnowMoreQuestionDone = true
            } else if (this.wantKnowMore && this.wantKnowMoreQuestionDone && this.showCarrusel) {
              //console.log('PINTAR CARRUSEL - id=1 ', document.getElementById('0'));
              let indexLastCarrusel = this.chatLog.length-1
              this.chatLog.forEach((element, index) => {
                if (element[2].includes('tarifaCarrousel')) {
                  indexLastCarrusel = index
                }
              });
              this.searchTarifas = this.chatLog[indexLastCarrusel]
              //this.searchTarifas = this.chatLog.find((element) => element[2].includes('tarifaCarrousel'))
              //console.log('searchTarifas ', this.searchTarifas);
              this.filterMessage(this.searchTarifas[2], source, question_id);
              setTimeout(() => {
                //console.log('timeout callback is invoked.');
                this.searchTarifas = [];
                //console.log('document.readyState DOM está listo ', document.readyState);
                //document.getElementById('1')?.scrollIntoView();
                this.wantKnowMore = false
                this.wantKnowMoreQuestionDone = false
                this.finalOfChat = true;
              });
            } else {
              //console.log('MIRAR SI PINTAR BTN EMPEZAR')
              this.checkSource(JSON.parse(parsedData.data).response.last_message)
            }

            // funcion para mostrar los iconos del feecback cuando termina el orquestador
            this.checkShowFeedback();

            console.log('Historial source ', this.sourceLog)
            console.log('Historial ', this.chatLog)
            console.log('FIN')
            console.log('')
          });
        }
      };
    }
  }

  /**
   * funcion para mostrar los iconos del feecback cuando termina el orquestador
   */
  checkShowFeedback() {
    this.chatLog.forEach(element => {
      //si el elemento contiene la etiqueta de feedback modificamos el valor de una propiedad de esa fila del listado
      if (element[4] == 'feedback') {
        element[6] = true;
      }
    });
  }

  /**
   * FUNCTION OF CHAT PREVIEW INSTANCE
   * USED TO AVOID AN EMPTY MESSAGE ON THE CONVERSATIONAL INSTANCE
   * @param input 
   */
  checkEntryInput(input: string) {
    if (input !== "") { //IS NOT EMPTY ?
      this.setInstance("3"); //THEN GO TO CONVERSATIONAL INSTANCE
    }
  }

  /**
   * FUNCION THAT ADD THE CHAT TO CHATLOG
   * [ HTML LOAD CHATLOG MESSAGES ]
   * @param optionalInput 
   * @param event 
   */
  addChat(optionalInput?: any, event?: any) {  
    console.log('addChat', optionalInput)

    if (optionalInput.value == '') {
    } else if (optionalInput.value == '__empty_milvus__ orquestador') {
      this.callOrquestador('__empty_milvus__', false, false)
    } else if (optionalInput.value == '__empty_milvus__' || optionalInput.label == 'Llamar a un agente') {
      this.callOrquestadorNoResponse('__empty_milvus__')
      console.log('llamar por telefono')
      let phoneNumber =  'tel:+34900100100'
      // Crear un enlace de teléfono
      const enlaceTeléfono = `tel:${this.phone1? this.phone1 : phoneNumber}`;
      // Abrir el enlace en el navegador
      window.location.href = enlaceTeléfono;
    } else if (optionalInput.value == 'quiero contratar orquestador') {
      this.callOrquestador('quiero contratar', false, false)
    } else if (optionalInput.value == 'Seguir explorando orquestador') {
      this.callOrquestador('quiero saber más', false, false)
    } else if (typeof optionalInput == 'object'){
      //console.log('Usuario envia object:', optionalInput.value.input.text)
      if (this.chatLog.length > 1 && this.chatLog[this.chatLog.length-2][2] == '¿Quieres información acerca de los dispositivos que tenemos disponibles en Vodafone?' && !optionalInput.value.input.text.includes('No')) {
          //console.log('Redirigir URL ')
          window.open(this.urlDispositivos, '_blank', 'location=yes');
      } else {
        this.callOrquestador(optionalInput.value.input.text, true, false)
      }
    } else {
      //console.log('Usuario envia:', optionalInput)
      this.callOrquestador(optionalInput, true, false)
    }
    console.log('')
  }

  /*
  decorateIAresponse(chatMessage: string, date: Date, result: any) {

    console.log("🟧 CHAT MESSAGE: " + chatMessage)
    console.log(result)

    switch (chatMessage) {

      case "pruebaTemporal":

        console.log("🟧 CASE: PRUEBA TEMPORAL ")

        this.iamessage += "";

        if (this.iamessage == "" || this.iamessage == null || this.iamessage == undefined) {
          this.iamessage = "<pre>" + "Disculpa las molestias, estamos experimentando problemas de conexión. Vuelva a intentarlo en unos segundos." +
            "</pre>";
          this.iamessage = this.formatWatsonText(this.iamessage)
        }
        else {
          this.iamessage = "<span>" + this.iamessage + "</span>";
          this.iamessage = this.formatWatsonText(this.iamessage)
        }

        //this.iamessage = "<pre>" + this.iamessage + "</pre>";

        //--CHECK TARIFA CARD NAMES--
        this.tarifaCardNames = this.getTarifaCardNames(chatMessage);

        this.iamessage = this.iamessage.replace(/##tarifaCarrousel##(.*?)##/g, '');

        if (this.tarifaCardNames != null) {
          this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "default tarifaCard", this.iaoptions, this.tarifaCardNames]);
          this.tarifaCardNames = null
        }
        //---------------------------
        else {
          //--CHECK IF IS FINAL MESSAGE--

          var pattern = /##quitInputText##/;

          // Test if the pattern is found in iamessageText
          if (pattern.test(this.iamessage)) {

            this.finalOfChat = true

            // Delete the pattern from this.iamessage
            this.iamessage = this.iamessage.replace(pattern, '');

            // Your code for handling the case where the pattern is found goes here
          }

          //-----------------------------

          if (this.iaoptions.length > 2) {
            this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "tarifasRow", this.iaoptions]);
          }
          else {
            this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "default", this.iaoptions]);
          }

        }

        if (this.iamessage) {
          console.log("🟪 OUTPUT: " + this.iamessage)
          if (this.iaoptions.length > 0) {
            console.log("🟪 OPTIONS OUTPUT: " + this.iaoptions)
            console.log("🟪 OPTIONS LENGHT: " + this.iaoptions.length)
          }
          else { console.log("🟪 NO IA OPTIONS") }
          console.log("➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖")
        }

        this.iamessage = ''
        this.iaoptions = []

        break;

      default:
        this.timeoutreached = false;

        console.log("🟧 CASE: DEFAULT ")

        if (result == null || result == undefined) {
          this.iamessage = "Disculpa las molestias, estamos experimentando problemas de conexión. Vuelva a intentarlo en unos segundos."
        }

        if (result.output != undefined) {
          result.output?.generic?.forEach((response: any) => {
            if (response.options) {
              response.options.forEach((option: any) => {
                this.iaoptions.push(option.label)
              });
            }

            if (response.text) {
              this.iamessage = this.iamessage + response.text
              this.iamessage = this.iamessage.replace(/\n\n/g, '<br>');
            }

            if (response.response_type == "date") {
              this.haveDate = true;
            }
          })
        }
        else {
          this.iamessage += result
          this.iamessage = this.iamessage.replace(/\n\n/g, '<br>');
        }

        if (result.output != undefined) {
          if (result.output.entities.length == 0) {
            if (this.iamessage == "" || this.iamessage == null || this.iamessage == undefined) {

              this.iamessage = "<pre>" + "Disculpa las molestias, estamos experimentando problemas de conexión. Vuelva a intentarlo en unos segundos." +
                "</pre>";
              this.iamessage = this.formatWatsonText(this.iamessage)
            }
            else {
              this.iamessage = "<pre>" + this.iamessage + "</pre>";
            }
            this.iamessage = this.formatNSText(this.iamessage)
          }
          else {
            if (this.iamessage == "" || this.iamessage == null || this.iamessage == undefined) {

              this.iamessage = "<span>" + "Disculpa las molestias, estamos experimentando problemas de conexión. Vuelva a intentarlo en unos segundos." +
                "</span>";
              this.iamessage = this.formatWatsonText(this.iamessage)
            }
            else {
              this.iamessage = "<span>" + this.iamessage + "</span>";
              this.iamessage = this.formatWatsonText(this.iamessage)
            }
          }
        }

        //--CHECK TARIFA CARD NAMES--
        this.tarifaCardNames = this.getTarifaCardNames(chatMessage);

        this.iamessage = this.iamessage.replace(/##tarifaCarrousel##(.*?)##/g, '');

        if (this.tarifaCardNames != null) {
          this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "default tarifaCard", this.iaoptions, this.tarifaCardNames, this.haveDate]);
          this.tarifaCardNames = null
          this.finalOfChat = true;
          this.timeoutreached = false;
          this.getResponseTime()
        }
        //---------------------------
        else {
          //--CHECK IF IS FINAL MESSAGE--

          var pattern = /##quitInputText##/;

          // Test if the pattern is found in iamessageText
          if (pattern.test(this.iamessage)) {

            this.finalOfChat = true

            console.log("❌ END OF CHAT")

            // Delete the pattern from this.iamessage
            this.iamessage = this.iamessage.replace(pattern, '');

            // Your code for handling the case where the pattern is found goes here
          }

          //-----------------------------

          if (this.iaoptions.length > 2) {
            this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "tarifasRow", this.iaoptions, undefined, this.haveDate]);
            this.timeoutreached = false;
            this.getResponseTime()
          }
          else {
            this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "default", this.iaoptions, undefined, this.haveDate]);
            this.timeoutreached = false;
            this.getResponseTime()
          }

        }

        if (this.iamessage) {
          console.log("🟪 OUTPUT: " + this.iamessage)
          if (this.iaoptions.length > 0) {
            console.log("🟪 OPTIONS OUTPUT: " + this.iaoptions)
            console.log("🟪 OPTIONS LENGHT: " + this.iaoptions.length)
          }
          else { console.log("🟪 NO IA OPTIONS") }
          console.log("➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖")
        }

        this.secondsremaining = 0;
        if (this.timeoutid !== null) {
          clearTimeout(this.timeoutid);
        }

        this.iamessage = ''
        this.iaoptions = []
        this.haveDate = false;
        this.timeoutreached = false;

        this.stopCountdown = true;

        break;
    }

    this.timeoutreached = false;

  }

  getResponseTime() {
    if (this.secondsremaining <= 0) {
      console.log("⬛ RESPONSE TIME: +10 SECONDS")
    }
    else {
      let secondsResponse = 10 - this.secondsremaining
      console.log("⬛ RESPONSE TIME: " + secondsResponse + " SECONDS")
    }
  }
  */

  /*
  rearrangeTimeoutMessages(): void {
    if (this.timeoutmessages.length > 1) {
      let latestMessage: any = this.timeoutmessages.pop();
      this.timeoutmessages.unshift(latestMessage);
    }
  }
  */

  /*
  checkTimeOutStep(responseText: any, chatMessage: string) {

    if (responseText.output.generic[0].text.includes("##pasoIntermedioNS##")) {

      this.inputDisabled = true;

      console.log("⬛ MIDDLE JUMP DONE")

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      //myHeaders.append("Authorization", environment.apiurlAuth);

      console.log('---------------------------------')
      console.log('checkTimeOutStep')
      //fetch(environment.apiurlConversationPartInitial + this.session_id + environment.apiurlConversationPartFinal, {
      //fetch(environment.apiOrquestador, {
      fetch(environment.apiOrquestadorLocal, {
        method: 'GET',
        headers: myHeaders,
        body: "",
        redirect: 'follow'
      })
        .then(response => response.json())
        .then(jsonResponse => {
          this.decorateIAresponse(chatMessage, this.date, jsonResponse)
          this.inputDisabled = false;
          this.timeoutreached = false;

        })
        .catch(error => {
          console.log("⚠️" + error);
        })
    }
    else {
      this.decorateIAresponse(chatMessage, this.date, responseText)
      this.inputDisabled = false;
      this.timeoutreached = false;

    }
  }*/

  /**
   * funcion que añade la hora al chat, llamada desde el html
   * @param event 
   */
  handleDateChange(event: any) {
    // Access the selected date value
    const selectedDate = event.target.value;

    // Call your function to check the value
    this.addChat(selectedDate, event);
  }

  /**
   * funcion que devuelve en un array el nombre de las tarjetas de la tarifas a pintar
   * @param value 
   * @returns 
   */
  getTarifaCardNames(value:string | any): string[] | null {
    //console.log('getTarifaCardNames')
    //console.log('getTarifaCardNames - value:', value)
    // Reset the array before searching to avoid accumulating previous results
    this.tarifaCardNames = [];

    let matches = value.match(/##tarifaCarrousel##(.*?)##/g);

    if (matches && matches.length > 0) {
      // Extract the content between markers and push it into the array
      matches.forEach((match: any) => {
        let content = match.replace(/##tarifaCarrousel##(.*?)##/, '$1');
        this.tarifaCardNames?.push(content);
      });

      //console.log(this.tarifaCardNames)

      return this.tarifaCardNames;
    }

    return null;  // Return null for the case where there are no matches
  }

  /***
   * funcion que obtiene el string necesario de las tarifas para enviarlo al feedback
   */
  getTarifasForFeedback(value:string | any): string {
    // Reset the array before searching to avoid accumulating previous results
    let result = '';

    let matches = value.match(/##tarifaCarrousel##(.*?)##/g);
    matches.forEach((match: any) => {
      result += match
    });

    return result;
  }

  /**
   * funcion que devuelve en un array el precio de las tarjetas de la tarifas a pintar
   * @param value 
   * @returns 
   */
  getTarifaCardPrices(value:string | any): string[] | null {
    //console.log('getTarifaCardPrices - value:', value)
    // Reset the array before searching to avoid accumulating previous results
    this.tarifaCardPrices = [] ;
    if(value.includes('€')){
      let auxIndex = value.indexOf('€');
      let aux = value.substring(auxIndex+1, value.length).trim()
      //console.log('getTarifaCardPrices - aux:', aux)
      //console.log('getTarifaCardPrices - aux length:', aux.length)
      //hay más de un precio
      if(value.includes(',')){
        aux = value.substring(auxIndex+1, value.length).trim()
        aux = aux.split(',')
        //console.log('getTarifaCardPrices - varios precios aux:', aux)
        //cambio los puntos por comas
        //console.log('getTarifaCardPrices - antes aux replace . por ,:')
        aux.forEach((element: string) => {
          element = element.replace('.', ',').trim();
        });
        //console.log('getTarifaCardPrices - despues aux replace . por ,:')
        //console.log('getTarifaCardPrices - aux length:', aux.length)
      } else {
        //solo hay uno
        //console.log('getTarifaCardPrices - un precio aux:', aux)
        aux = aux.replace('.', ',');
        aux = [aux]
      }
      this.tarifaCardPrices = aux
    }
    return this.tarifaCardPrices != undefined ? this.tarifaCardPrices : null;
  }

  /**
   * Funcion que formatea el texto que recibimos del orquestador
   * @param iamessage 
   * @returns 
   */
  formatWatsonText(iamessage: string) {

    console.log("⬜ WATSON TYPE TEXT")

    iamessage = iamessage.replace(/\.{3}/g, "[etc]");

    // Split the text into an array of sentences using period as the delimiter
    var breaks = iamessage.split(/\.(?!com|\.{3})/);

    // Join the sentences back together with a line break after each period
    var iamessageProccesed = breaks.join(".<br><br>");

    // Use a regular expression to match three consecutive <br> tags
    var regex = /<br\s*\/?>\s*<br\s*\/?>\s*<br\s*\/?>/gi;

    // Replace the matched pattern with a period and newline
    var iamessageProccesedBR = iamessageProccesed.replace(regex, '<br><br>');

    // Use a regular expression to match a single <br> tag with optional white space
    var regex = /(?<!<br\s*\/?>)\s*<br\s*\/?>\s*(?!<br\s*\/?>)/gi;

    // Replace the matched pattern with two <br> tags
    var iamessageProccesedBR = iamessageProccesedBR.replace(regex, '<br>');

    // Define the pattern for the text you want to replace
    var pattern = /\[([^\]]+)\]\(([^)]+)\)/;

    // Find the match in the original text
    var match = iamessageProccesedBR.match(pattern);

    if (match) {
      var linkText = match[1]; // Text inside []
      var linkHref = match[2]; // Text inside ()

      // Replace the found text with the <a> tag
      iamessageProccesedBR = iamessageProccesedBR.replace(pattern, `</span><a href="${linkHref}">${linkText}</a><span>`);
    }

    iamessageProccesedBR = iamessageProccesedBR.replace(/\[etc\]/g, "...");

    return iamessageProccesedBR;
  }

  /**
   * Funcion que formatea el texto que recibimos del orquestador, elimina las etiquetas o caracteres raros
   * @param iamessage 
   * @returns 
   */
  formatNSText(iamessage: string) {

    console.log("⬜ NS TYPE TEXT");
    console.log(iamessage)

    // Replace consecutive newline characters with an empty string
    iamessage = iamessage.replace(/\n/g, '');

    // Replace consecutive newline characters with an empty string
    iamessage = iamessage.replace(/<br>/g, '');

    // Remove consecutive occurrences of "<br><br>""
    iamessage = iamessage.replace(/<br><br>+/g, '');

    // Detect and replace ... with [etc]
    iamessage = iamessage.replace(/\.{3}/g, "[etc]\n");

    iamessage = iamessage.replace(/(\d+)\./g, "[$1dot]");

    iamessage = iamessage.replace(/:/g, ":\n");

    // Detect and replace EE. UU or EE.UU with [EEUU]
    iamessage = iamessage.replace(/EE(\.|\. |\. )?UU(\.| )?/g, '[EEUU]');

    var breaks = iamessage.split(/\.(?!com|\.{3})/);

    for (var i = 0; i < breaks.length; i++) {
      breaks[i] = breaks[i].trim(); // Remove white space after the dot
    }

    iamessage = breaks.join(".\n\n");

    // If the text ends with "\n", remove it
    iamessageProcessed = iamessage.replace(/\n$/, '');

    var breaks = iamessageProcessed.split('<br>');
    var iamessageProcessed = breaks.join("\n");

    // Use a regular expression to match three consecutive <br> tags
    var regex = /<br\s*\/?>\s*<br\s*\/?>\s*<br\s*\/?>/gi;

    // Replace the matched pattern with a period and newline
    var iamessageProcessedBR = iamessageProcessed.replace(regex, '\n \n');

    // Replace [etc] with ...
    iamessageProcessedBR = iamessageProcessedBR.replace(/\[etc\]/g, "...");
    iamessageProcessedBR = iamessageProcessedBR.replace(/\[(\d+)dot\]/g, "$1.");
    // Replace [EEUU] with EE.UU
    iamessageProcessedBR = iamessageProcessedBR.replace(/\[EEUU\]/g, 'EE.UU');

    iamessageProcessedBR = iamessageProcessedBR.replace(/(¿Quieres preguntarme algo más\?)/g, "\n$1");

    iamessageProcessedBR = iamessageProcessedBR.replace(/(\*\s[\w\d\s])/g, "\n\n$1");

    return iamessageProcessedBR;
  }

  /**
   * funcion que procesa el nombre de la tarifa y lo devuelve en minusculas con la ruta de la imagen
   * @param tarifaName 
   * @returns 
   */
  processImagePath(tarifaName: string) {
    // Remove white spaces and accent marks
    const tarifaNameNew = tarifaName.replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    //console.log('processImagePath - tarifaNameNew:', tarifaNameNew.toLowerCase())
    //console.log('../assets/images/tarifas/' + tarifaNameNew.toLowerCase() + 'mini.webp')

    // Convert to lowercase
    //return '../assets/images/tarifas/' + tarifaNameNew.toLowerCase() + '.webp';
    return '../assets/images/tarifas/' + tarifaNameNew.toLowerCase() + '.png';
  }

  /*
  //FUNCTION FOR IA CHAT, TO SHOW SPINNER AND THEN REPLACE FOR THE REAL MESSAGE
  decorateIAresponseTarifas(chatMessage: string, date: Date, result: any, optionableChatMode?: boolean) {
    var iaoptions: String[] = [];
    if (result.output != undefined) {
      result.output.generic.forEach((options: any) => {
        if (options.options) {
          iaoptions.push(options.options.label)
        }
      });
    }

    this.chatLog[this.chatLog.length - 1] = ([chatMessage, date, this.iamessage, "default", iaoptions]);
    this.iamessage = '' //RESET IA MESSAGE
  }
  */

  /*
  makeWatsonHandShake() {
    console.log('---------------------------------')
    console.log('makeWatsonHandShake')
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", environment.apiurlAuth);
    //fetch(environment.apiurlConversationPartInitial + this.session_id + environment.apiurlConversationPartFinal,
    //fetch(environment.apiOrquestador + this.session_id + environment.apiurlConversationPartFinal,    
    fetch(environment.apiOrquestadorLocal + this.session_id + environment.apiurlConversationPartFinal,
      {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      })
      .then(response => {
        response.json()      
        console.log("🟩 response", response.json())
      })
      .catch(error => console.log("⚠️" + error))
      .finally(() => {
        console.log("🟩 HANDSHAKE DONE")
        this.addChat();
        //THIS IS THE FIRST CHAT BOX OF THE CHATLOG IN THIS CLEARED THIRD INSTANCE,
        //BECAUSE WHEN THIRD INSTANCE IS SETTED, THE FIRST CHATLOG IS CLEARED
        //AFTERWARDS, THE TRIGGER TO ADD CHATLOGS IS THE SEND BUTTON IN THE INPUT OF THE CONVERSATIONAL INSTANCE
      })
  }
  */

  /*
  getIAmessage(chatInputValue: string): Promise<any> {
    console.log('---------------------------------')
    console.log('getIAmessage')
    this.secondsremaining = 10;

    this.stopCountdown = false;
    this.timeoutreached = false;

    console.log("🟥 VALUE IN PARAMS: " + chatInputValue)
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", environment.apiurlAuth);
      var raw;
      if (this.currentChatPath == "firstInteraction") {
        console.log("🟥 USER ENTRY INPUT: " + this.chatEntryInputValue)
        raw = JSON.stringify({
          "input": {
            "text": this.chatEntryInputValue
          }
        });
      }
      else {
        console.log("🟥 USER INPUT: " + chatInputValue)
        raw = JSON.stringify({
          "input": {
            "text": chatInputValue
          }
        });
      }
      // fetch(environment.apiurlConversationPartInitial + this.session_id + environment.apiurlConversationPartFinal,
      //fetch(environment.apiOrquestador,
      fetch(environment.apiOrquestadorLocal,
        {
          method: 'GET',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve("<pre>" + "Disculpa las molestias, estamos experimentando problemas de conexión. Vuelva a intentarlo en unos segundos." +
          "</pre>"));
    })
  }
  */

  /**
   * funcion que abre y cierra el modal, tambien vacia la caja de texto para que el usuario pueda volver a escribir
   */
  openIAModal() {
    this.modalSwitch = !this.modalSwitch;    
    this.chatEntryInputValue = "";
  }

  /**
   * funcion cuando pulsan el boton quiero saber más, recibe el nombre de la tarifa y el evento
   * @param tarifaCard 
   * @param event 
   */
  iWantToKnowMore(tarifaCard: string, event?: any) {
    this.wantKnowMore = true
    this.tarifaCardNameSelected = tarifaCard
    this.addChat('Quiero saber más acerca de la tarifa ' + tarifaCard, event)
  }

  /**
   * funcion que abre una nueva pestaña y pinta de nuevo la tarifa sin el boton de lo quiero
   * @param url 
   */
  iWantIt(tarifaCard: string, question_id: any){
    //console.log('iWantIt - question_id ', question_id);
    //console.log('iWantIt - tarifaCard: ', tarifaCard);
    //console.log('vodafoneWeb ', this.vodafoneWeb)
    let url = this.getLinkImage(tarifaCard);
    //console.log('getLinkImage - url: ', url)
    window.open(url, '_blank', 'location=yes');
    this.ngZone.run(() => {
      this.onlyMoreBtn = true;
      let listCardSelected = [];
      listCardSelected.push(tarifaCard);
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, tarifaCard, "default tarifaCard", tarifaCard, listCardSelected, true, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)
      //console.log('callOrquestador - chatLog length ', this.chatLog.length);
      console.log('Historial source ', this.sourceLog)
      console.log('Historial', this.chatLog);
      console.log('')
      this.loadFrame(tarifaCard);

      //envio feedback cuando pulsamos al boton lo quiero
      this.sendFeedbackIWantItBtnNoResponse(question_id)
    });
  }

  /**
   * la funcion que muestra el iframe en el front
   */
  loadFrame(tarifaCard: string) {
    this.ngZone.run(() => {
      this.currentChatPath = "secondInteraction";
      //desactivamos el modal
      this.modalSwitch = false;
      //this.chatEntryInputValue = 'Quiero saber mas'
      this.chatEntryInputValue = ''
      //this.showWeb = true;
      //console.log('loadFrame - showWeb ', this.showWeb)
      //this.instance = '0';
      //this.widthIframe = window.innerWidth;
      //this.heightIframe = window.innerHeight;
      this.callOrquestadorNoResponse(tarifaCard)
    });
  }

  /**
   * funcion llamada cuando se pulsa la bolita de la IA para que mueste la pantalla donde se interactua con la IA
   */
  talkToIA() {
    console.log('talkToIA')
    // console.log('talkToIA - chatLog.length', this.chatLog.length)
    if (this.chatLog.length > 0) {
      // console.log('talkToIA - hay historial')
      // console.log('talkToIA - chatEntryInputValue', this.chatEntryInputValue)
      this.instance = '3';
      if (this.chatEntryInputValue != '') {
        this.callOrquestador(this.chatEntryInputValue, true, false)
      }
    } else {
      // console.log('talkToIA - no hay historial')
      if(this.withPhone && !this.askForPhone) {
        this.setInstance('4')
      } else {
        this.setInstance('2')
      }
    }
    console.log('instance: ', this.instance)
  }
  
  /**
   * funcion que llama al orquestador pasandole la tarifa pero no pinta la respuesta del orquestador en el front
   * @param valueToSend 
   */
  async callOrquestadorNoResponse(valueToSend: string){
    if(valueToSend.length > 0) {
      console.log('INICIO')
      console.log('Call Orquestador - enviamos: ', valueToSend)
      //inicializamos la fecha y hora
      this.date = new Date();
      //variable donde guardamos el mesnaje del chatBot que mostraremos en el front
      this.iamessage = '';
      //variable donde vamos a guardar todas las opciones de tarifas que recibamos de la consulta
      this.iaoptions = [];
      //variable que oculta el boton de Lo quiero cuando esta en true
      this.onlyMoreBtn = false;

      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id);
      console.log('url orquestador:', this.url_servicio + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      const events = new EventSource(this.url_servicio + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id);
      events.onmessage = (event) => {
        const parsedData = event as any;
        
          //comprobamos si es el último mensaje que devuelve el orquestador
        if (JSON.parse(parsedData.data).response.last_message !== undefined && JSON.parse(parsedData.data).response.last_message) {
          //console.log('callOrquestadorNoResponse - mensaje_stream: ', this.chatLog[this.chatLog.length-1][2])
          //console.log('callOrquestadorNoResponse FIN - last_message: ', JSON.parse(parsedData.data).response.last_message)
          console.log('FIN')
          events.close();
          this.ngZone.run(() => {
            console.log('Historial source ', this.sourceLog)
            console.log('Historial', this.chatLog)
            console.log('')
            
            //vaciamos la caja de texto para que el usuario pueda volver a escribir
            this.chatEntryInputValue = '';
          });
        }
      };
    }
  }
  
  /**
   * funcion que llama al orquestador pasandole la tarifa pero no pinta la respuesta del orquestador en el front
   * @param valueToSend 
   */
  async sendFeedbackNoResponse(valueToSend: any, question_id: any){
    console.log('INICIO')
    console.log('send Feedback - enviamos: ', valueToSend)

    var imgBtn = document.getElementById(question_id+valueToSend)
    //console.log(imgBtn)
    imgBtn?.classList.add('feedback_selected')
    var chatBotBtn = document.getElementById(question_id)
    chatBotBtn?.classList.add('removePointerEvents')

    //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
    //const events = new EventSource('http://localhost:8000/postgre?question_id=573865737&session_id=38537783738&feedback=positivo');
    console.log('url orquestador:', this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&feedback='+ encodeURIComponent(valueToSend))
    const events = new EventSource(this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&feedback='+ encodeURIComponent(valueToSend));
    events.onmessage = (event) => {
      const parsedData = event as any;
    };

    if(valueToSend == 0 && !this.showCarrusel){
      //this.callOrquestadorNoResponse('__empty_milvus__')
      //añadimos el texto y 3 botones
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push([
        '',
        this.date,
        '¡Vaya! Siento que la respuesta no te haya gustado. ¿En qué más te puedo ayudar?',//'Siento que no te haya gustado mi respuesta. ¿Quieres contactar con un agente?',
        "default",
        undefined,
        undefined,
        false,
        undefined
      ]);
      this.chatLog.push([
        '',
        this.date,
        [{label:'Encontrar la mejor tarifa para mi', value:'quiero contratar orquestador'},
        {label:'Seguir explorando', value:'Seguir explorando orquestador'},
        {label:'Llamar a un agente', value:'__empty_milvus__ orquestador'}],
        "default",
        undefined,
        undefined,
        undefined
      ]);
      this.sourceLog.push(['CODE',''])
      this.sourceLog.push(['CODE',''])
      console.log('Historial sourceLog: ', this.sourceLog)
      console.log('Historial: ', this.chatLog)
    }
  }

  checkIfDifferentCarrusel(message: any){ 
    let result = false   
    //console.log('checkIfDifferentCarrusel')
    //console.log('message', message)
    this.chatLog.forEach(element => {      
      if(element[2]!= undefined && element[2].includes('##')){ 
        //console.log('element[2]', element[2])
        if(element[2] == message){
          result = true
          //console.log('MISMA TARIFA')
        }
      }
    });
    return result
  }

  async sendFeedbackCarruselNoResponse(question_id: any, message: any, carrusel: any){
      //console.log('sendFeedbackCarruselNoResponse')
    let result = this.checkIfDifferentCarrusel(message)
    if(question_id.length > 0 && result) {
      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/postgre?question_id=573865737&session_id=38537783738&feedback=positivo');
      //https://orquestador.1gppixdrrwu6.eu-de.codeengine.appdomain.cloud/postgre?question_id=942b80d6-7320-4322-bd48-d5dea28ae32b&session_id=e324d72d-e2de-408b-bcba-4961ff5b1b83&carrusel=''
      console.log('url orquestador:', this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&carrusel=' + encodeURIComponent(carrusel))
      const events = new EventSource(this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&carrusel=' + encodeURIComponent(carrusel));
      events.onmessage = (event) => {
        const parsedData = event as any;
      };
    }
  }
  async sendFeedbackIWantItBtnNoResponse(question_id: any){
    if(question_id.length > 0) {
      console.log('sendFeedbackIWantItBtnNoResponse')

      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/postgre?question_id=573865737&session_id=38537783738&feedback=positivo');
      //https://orquestador.1gppixdrrwu6.eu-de.codeengine.appdomain.cloud/postgre?question_id=942b80d6-7320-4322-bd48-d5dea28ae32b&session_id=e324d72d-e2de-408b-bcba-4961ff5b1b83&lead=''
      console.log('url orquestador:', this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&lead=1')
      const events = new EventSource(this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&lead=1');
      events.onmessage = (event) => {
        const parsedData = event as any;
      };
    }
  }

  /**
   * Funcion que obtiene la url a pintar segun el parametro que reciba con el nombre de la tarifa seleccionada
   * @param imgName 
   */
  getLinkImage(imgName: string) {
    const tarifaNameNew = imgName.replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    console.log('getLinkImage - tarifaNameNew: ', tarifaNameNew)
    let url = this.vodafoneWeb
    if (this.isPortability){
      switch(tarifaNameNew){
        case '25gb-600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764994&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '50gb-600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764998&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '100gb-600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764998&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '25gb-1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764970&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '50gb-1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764972&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '100gb-1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764976&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'yumovil+fibra':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2457140&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadabasica600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739371&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadabasica1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739377&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadamax600mb':
            url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739286&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadamax1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1788808&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadaduo600mb':
           if (this.tvSelected == 'hbo_img' ) {
            url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1740734&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          } else if (this.tvSelected == 'disney_img') {
            url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739471&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          }
          break;
        case 'oneilimitadaduo1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739435&tcli=0&tta=13?entrypoint=vodafone%20magica&entryloc=not%20defined'
          //if (this.tvSelected == 'hbo_img' ) {
            //url = 'www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739435&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          //} else if (this.tvSelected == 'disney_img') {
            //url = 'www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739476&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          //}
          break;
        case 'oneilimitadamaxtvbasica600mb':
          url = 'https://vodafone.es?entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadamaxtvbasica1gb':
          url = 'https://vodafone.es?entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'tarifahogarilimitable2lineas':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739416&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'tarifahogarilimitable3lineas':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1740307&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'tarifahogarilimitable4lineas':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1741597&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        default:
          url = 'https://vodafone.es?entrypoint=vodafone%20magica&entryloc=not%20defined'
          break
      }
    } else {
      switch(tarifaNameNew){
        case '25gb-600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2765008&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '50gb-600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764990&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '100gb-600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764992&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '25gb-1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764970&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '50gb-1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764984&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case '100gb-1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2764986&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'yumovil+fibra':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2456744&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadabasica600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739385&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadabasica1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739387&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadamax600mb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2398951&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadamax1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=2398893&tcli=0&tta=1&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadaduo600mb':
           if (this.tvSelected == 'hbo_img' ) {
            url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1740734&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          } else if (this.tvSelected == 'disney_img') {
            url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739471&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          }
          break;
        case 'oneilimitadaduo1gb':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739435&tcli=0&tta=13?entrypoint=vodafone%20magica&entryloc=not%20defined'
          //if (this.tvSelected == 'hbo_img' ) {
            //url = 'www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739435&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          //} else if (this.tvSelected == 'disney_img') {
            //url = 'www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739476&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          //}
          break;
        case 'oneilimitadamaxtvbasica600mb':
          url = 'https://vodafone.es?entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'oneilimitadamaxtvbasica1gb':
          url = 'https://vodafone.es?entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'tarifahogarilimitable2lineas':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1739416&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'tarifahogarilimitable3lineas':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1740307&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        case 'tarifahogarilimitable4lineas':
          url = 'https://www.vodafone.es/c/tienda-online/particulares/contratacion?id=1741597&tcli=0&tta=13&entrypoint=vodafone%20magica&entryloc=not%20defined'
          break;
        default:
          url = 'https://vodafone.es?entrypoint=vodafone%20magica&entryloc=not%20defined'
          break
      }
    }
    //console.log('getLinkImage - url: ', url)
    return url
  }

  /**
   * funcion que vuelve a mostrar la tarifa despues de la respuesta al pulsar el boton saber más
   */
  async showCardAgain() {    
    this.ngZone.run(() => {
      let listCardSelected = [];
      listCardSelected.push(this.tarifaCardNameSelected);
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, this.tarifaCardNameSelected, "default tarifaCard", this.tarifaCardNameSelected, listCardSelected, false, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)
      console.log('showCardAgain - chatLog length ', this.chatLog.length);
      console.log('showCardAgain - chatLog ', this.chatLog);
      console.log('showCardAgain - chatLogId ', this.chatLogId);
      this.wantKnowMore = false;
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Oper
      //document.body.scrollTop = 0; // For Safari
      console.log('FALTA SCROLL al texto anterior')
      console.log('showCardAgain - id=1 ', document.getElementById('1'));
      //document.getElementById('1')?.focus();
      //document.getElementById('1')?.scroll(0,0);
    });
  }

  /**
   * funcion que comprueba la fuente de la info del orquestador, si cumple las conduciones pinta texto y un boton
   * @param last_message 
   */
  checkSource(last_message: boolean) {
    if (this.sourceLog.length>1 && this.sourceLog[this.sourceLog.length-1][0] == 'API' && this.sourceLog[this.sourceLog.length-2][0] == 'WATSON'){
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, '¿Quieres que te ayude a encontrar la mejor tarifa para ti?', "default", undefined, undefined, undefined]);
      let listBtn = []
      listBtn.push({label: 'Si', value: {"input": {"text": "Si"}}})
      listBtn.push({label: 'No', value: {"input": {"text": "No"}}})
      this.chatLog.push(['', this.date, listBtn, "default startBtn", undefined, undefined, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)      
      console.log('last_message: ' + last_message + ' - Source: ' + this.source + ' - Respuesta orquestador: ', 'Conoce qué tarifa se adapta mejor a ti')
      this.finalOfChat = false;
    }
  }

  /**
   * funcion llamada desde el html cuando se pulsa un boton de TV
   * @param value 
   */
  selectTV(value: string){
    const hbo_img = document.getElementById('hbo_img');
    const disney_img = document.getElementById('disney_img');
    if (hbo_img && disney_img){
      if(value == 'hbo_img'){
        hbo_img.className = "imgSelected";
        disney_img.className = "";
      } else if(value == 'disney_img'){
        hbo_img.className = "";
        disney_img.className = "imgSelected";
      }
    }
    this.tvSelected = value
  }

  savePhone() {
    // Comprobar si el valor de userPhone es un número de teléfono válido
    this.askForPhone = true
    if (this.errorPhone) {
      // Lógica para guardar el número de teléfono
      console.log('Número de teléfono válido');
      // enviamos el telefono desde el iframe
      window.parent.postMessage(this.userPhone, '*');
      window.parent.postMessage('acceptPrivacyPolicy=' + this.acceptPrivacyPolicy, '*');
      window.parent.postMessage('acceptCommercialCommunications' + this.acceptCommercialCommunications, '*');    
      //console.log(this.userPhone, this.acceptPrivacyPolicy, this.acceptCommercialCommunications);
      console.log('Se ha enviado el teléfono');
      this.setInstance('2')
    } else {
      this.errorPhone = false
      // Mostrar un mensaje de error o realizar otra acción
      console.error('Número de teléfono no válido');
    }
  }

  isValidPhone(phone: string): boolean {
    // Expresión regular para validar el formato de un número de teléfono
    const phoneRegex = /^[0-9]{9}$/;
    return phoneRegex.test(phone);
  }
  
  validatePhone() {
    // Agrega tu lógica de validación de teléfono aquí
    //console.log('phone:', this.userPhone);
    this.errorPhone = this.isValidPhone(this.userPhone);
    this.checkButton()
  }

  closePopup() {
    this.setInstance('1')
  }

  checkButton(){
    if(this.userPhone.length == 9 && this.errorPhone && this.acceptPrivacyPolicy)
      this.disabledSendBtn = false
    else 
      this.disabledSendBtn = true
  }

  minimize() {
    this.setInstance('1');
    console.log('instance: ', this.instance)
    window.parent.postMessage('minimizar', '*');
    let instanceAux = this.instanceToSend()
    window.parent.postMessage('instance=' + instanceAux, '*');
    console.log('instanceToSend: ', instanceAux)
  }

  minimizeFromChat() {
    this.chatLog.length > 0 ? this.setInstance('1'): this.setInstance('2')
    sessionStorage.setItem('chatLog', JSON.stringify(this.chatLog));
    sessionStorage.setItem('sourceLog', JSON.stringify(this.sourceLog));
    window.parent.postMessage('minimizar', '*');
    console.log('instance: ', this.instance)
    let instanceAux = this.instanceToSend()
    console.log('instanceToSend: ', instanceAux)
    window.parent.postMessage('instance=' + instanceAux, '*');
  }

  instanceToSend() {
    let result = this.instance
    // Obtener el valor guardado en el almacenamiento local
    const chatLogsessionStorage = sessionStorage.getItem('chatLog');
    const sourceLogsessionStorage = sessionStorage.getItem('sourceLog');

    if (chatLogsessionStorage && sourceLogsessionStorage) {
      result = '3'
    } else {
      result = '2'
    }
    return result
  }
}