<!--
<div class="d-sm-block d-none desktopInstance">
  <img src="../assets/images/fullDesktop.png">
</div>
-->
<div *ngIf="instance === '0'" class="firstInstance d-sm-none d-block">
  <div *ngIf="!modalSwitch" class="ia_popup_button" (click)="talkToIA()">
    <img src="../assets/images/Badge.png" alt="Vodafone Magica"/>
  </div>
  <iframe [width]="widthIframe" [height]="heightIframe" [src]="urlWeb" class="urlWeb"></iframe>
</div>

<div *ngIf="instance === '1'" class="firstInstance d-sm-none d-block">
  <!--
  <div class="fixed-top headerVodafone">
    <img src="../assets/images/webImages/nav-1.png">
    <img src="../assets/images/webImages/nav-2.png">
  </div>
  <div class="blockMagica">
    <div class="vodafoneMagicaText">
      <div class="firstInstanceText">
        <p>Prueba</p>
        <h1>Mágica</h1>
        <span>Mágica es una nueva forma de comunicarte con Vodafone, más humana, más sencilla. </span>
      </div>
    </div>
    <button class="firstInstanceButton" (click)="chatLog.length > 0 ? setInstance('3'): setInstance('2')">¿Hablamos?</button>
  </div>
  <img src="../assets/images/webImages/fullPageV3.png" class="w-100 imgBody">

  <div class="footerVodafone fixed-bottom">
  </div>
-->
  <div *ngIf="!modalSwitch && !isCallInIframe" class="ia_popup_button" (click)="talkToIA()">     
    <img src="../assets/images/Badge.png" alt="Vodafone Magica"/>
  </div>
</div>

<div *ngIf="instance === '2'" class="secondInstance d-sm-none d-grid">
  <div class="headerVodafone fixed-top">
    <img src="../assets/images/webImages/nav-3.png" (click)="minimize()">
    <img src="../assets//images/minimizar.png" class="minimizar" (click)="minimize()">
  </div>

  <div class="virtualBack">
    <img src="../assets/images/fullPng_onlySVG.png">
    <div class="secondInstanceText">
      <br/><br/>
      <h1>¡Hola!, soy Mágica</h1>
      <span>Mágica, el chat basado en Inteligencia Artificial que te ayudará a conocer las ofertas de productos y servicios de Vodafone</span>
      <br/><br/>
      <span class="smallText">Al iniciar una conversación aceptas la <a target="_blank" href="https://www.vodafone.es/c/statics/tc2-magica.pdf">Política de uso y Privacidad de Mágica</a>.  No te pediremos tus datos personales ni es necesario que los aportes.</span>
    </div>
  </div>

  <div class="footerWatson fixed-bottom">
    <div class="inputWatson">
      <input type="text" [(ngModel)]="chatEntryInputValue" placeholder="¿En qué puedo ayudarte?" autofocus >
      <div class="sendButtonArrow" (click)="checkEntryInput(chatEntryInputValue)"></div>
    </div>
  </div>
</div>

<div *ngIf="instance === '3'" class="thirdInstance d-sm-none d-grid">
  <div class="fixed-top headerVodafone">
    <img src="../assets/images/fa-arrow.png" (click)="minimizeFromChat()">
    <span><strong>Mágica</strong></span>
    <span></span>
  </div>

  <div></div>

  <div class="chatContainer" *ngIf="chatLog.length>0">

    <!--SPINNER AT CHAT BEGINING-->


    <div class="chat-message" id={{i.toString()}} *ngFor="let chatMessage of chatLog?.slice()?.reverse(); let i = index">

      <!-- Conversacoin del usuario -->
      <div *ngIf="chatMessage[0]!=''" class="userChatBox" [hidden]="chatMessage[4]">
        <div class="chat">
          <div class="chatDecorator">
            <span>Tú</span>
            <span>{{ chatMessage[1] | date: 'H:mm' }}</span>
          </div>
          {{ chatMessage[0] }}
        </div>
      </div>

      <!-- Conversacion del Box -->
      
      <!-- Seccion botones -->
      <ng-container *ngIf="chatMessage[3] == 'default startBtn'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div *ngIf="checkTypeof(chatMessage[2]) === 'object'" class="chatOptionsIA">
            <button (click)="callOrquestador('quiero contratar', false, false)">Si</button>
            <button (click)="callOrquestador('quiero saber más', false, false)">Seguir explorando</button>
          </div>
        </div>
      </ng-container>
      
      <!-- Seccion Botones respuesta del chatBot -->
      <ng-container *ngIf="chatMessage[3] == 'default' || chatMessage[3] == 'tarifasRow'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div *ngIf="checkTypeof(chatMessage[2]) === 'object'" class="chatOptionsIA">
            <ng-container *ngFor="let option of chatMessage[2]">
              <button (click)="addChat(option, $event)">{{ option.label }}</button>
            </ng-container>
          </div>

          <div *ngIf="checkTypeof(chatMessage[2]) !== 'object'" [ngClass]="{
            'chat': chatMessage[2].length > 1,
            'chatLoader': !chatMessage[2],
            'chatLoaderSpinner': this.timeoutreached
          }">
            <div class="chatDecorator" *ngIf="chatMessage[2]">
              <span><strong>Mágica</strong></span>
              <span [innerHTML]="chatMessage[1] | date: 'H:mm'"></span>
            </div>
            <span *ngIf="!chatMessage[2] && !this.timeoutreached" class="loader"></span>
            <span class="textPensando" *ngIf="this.timeoutreached && !chatMessage[2]">
              {{ this.timeoutmessages[0] }} <i class="fa-solid fa-hourglass fa-spin"></i>
            </span>
            <ng-container *ngIf="chatMessage[4] == 'feedback'">
                <div class="chatBotText">
                  <span [innerHTML]="chatMessage[2]"></span>
                </div>
                <div *ngIf="chatMessage[4] == 'feedback' && chatMessage[6]" class="chatBotBtn" [id]="chatMessage[5]">
                  <a (click)="sendFeedbackNoResponse(1, chatMessage[5])"><img [id]="chatMessage[5]+'1'" src="../assets/images/pulgar_arriba.png"></a>
                  <a (click)="sendFeedbackNoResponse(0, chatMessage[5])"><img [id]="chatMessage[5]+'0'" src="../assets/images/pulgar_abajo.png"></a>
                </div>
            </ng-container>
            <ng-container *ngIf="chatMessage[4] != 'feedback'">
              <span [innerHTML]="chatMessage[2]"></span>
            </ng-container>
            <ng-container *ngIf="chatMessage[4] != 'feedback' && chatMessage[6]">
              <br><input type="date" (change)="handleDateChange($event)">
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Seccion de dispositivos -->
      <ng-container *ngIf="chatMessage[3] == 'default dispositivos'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div [ngClass]="{ 'chat': chatMessage[3] }">
            <div class="chatDecorator" *ngIf="chatMessage[2]">
              <span><strong>Mágica</strong></span>
              <span [innerHTML]="chatMessage[1] | date: 'H:mm'"></span>
            </div>
            <div class="tarifaBox">
              <span [innerHTML]="chatMessage[4]"></span>              
              <div class="tarifaCardsCarrousel">
                <img class="dipositivosImg" [src]="processImagePath(chatMessage[5])"/>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Seccion tarjetas de las tarifas y los botones -->
      <ng-container *ngIf="chatMessage[3] == 'default tarifaCard'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div [ngClass]="{ 'chat': chatMessage[3] }">
            <div class="chatDecorator" *ngIf="chatMessage[2]">
              <span><strong>Mágica</strong></span>
              <span [innerHTML]="chatMessage[1] | date: 'H:mm'"></span>
            </div>
            <div class="tarifaBox">
              <span [innerHTML]="chatMessage[4]"></span>              
              <div *ngIf="chatMessage[5] && chatMessage[5].length > 0" class="tarifaCardsCarrousel">
                  <div class="tarifaCardsCarrousel">
                    <ng-container *ngFor="let tarifaCard of chatMessage[5]; let i = index">
                      <div class="tarifaCardsCarrouselCard">
                        <!--<img src="../assets/images/tarifas/{{ processImagePath(tarifaCard) }}mini.webp">-->
                        <!--
                        -->
                        <img [ngClass]="chatMessage[5].length == 1 ? 'w-100':''" [src]="processImagePath(tarifaCard)">

                        <!-- 3 lineas - Hogar ilimitable 3 líneas y 4 lineas - Hogar ilimitable 4 líneas -->                        
                        <!--
                        <div *ngIf="chatMessage[5][i] == 'Tarifa hogar ilimitable 3 líneas' || chatMessage[5][i] == 'Tarifa hogar ilimitable 4 líneas'" class="tv_section tvOneOption">
                          <a (click)="selectTV('hbo_disney_img')"><img id='hbo_disney_img' class="imgSelected" src="../assets/images/tv/hbo_disney_prime.webp"/></a>
                        </div>
                        -->
                        <!-- 2 lineas
                              600 MB - One ilimitada Duo 600MB
                              1 GB - 
                                Tarifa hogar ilimitable 2 líneas
                                One ilimitada Duo 1GB
                        -->
                        <!--
                        <div *ngIf="chatMessage[5][i] == 'One ilimitada Duo 600MB'" class="tv_section">
                          <a (click)="selectTV('hbo_img')"><img id='hbo_img' class="imgSelected" src="../assets/images/tv/hbo_prime.webp"/></a>
                          <p>ó</p>
                          <a (click)="selectTV('disney_img')"><img id='disney_img' src="../assets/images/tv/disney_prime.webp"/></a>
                        </div>
                        <div *ngIf="chatMessage[5][i] == 'Tarifa hogar ilimitable 2 líneas'" class="tv_section tvOneOption">
                          <a (click)="selectTV('hbo_disney_img')"><img id='hbo_disney_img' class="imgSelected" src="../assets/images/tv/hbo_disney_prime.webp"/></a>
                        </div>
                        <div *ngIf="chatMessage[5][i] == 'One ilimitada Duo 1GB'" class="tv_section">
                          <a (click)="selectTV('hbo_img')"><img id='hbo_img' class="imgSelected" src="../assets/images/tv/hbo_prime.webp"/></a>
                          <p>ó</p>
                          <a (click)="selectTV('disney_img')"><img id='disney_img' src="../assets/images/tv/disney_prime.webp"/></a>
                        </div>
                        -->
                        <!-- 1 lineas
                              300 MB - Yumovil+fibra 300MB
                              600 MB -
                                One ilimitada Max 600MB
                                One ilimitada Basica 600MB
                              1 GB - 
                                One ilimitada 1GB
                                One ilimitada Basica 1GB
                        -->
                        <!--
                        <div *ngIf="chatMessage[5][i] == 'One ilimitada MAX 600MB'" class="tv_section tvOneOption">
                          <a (click)="selectTV('disney_70canales_img')"><img id='disney_70canales_img' class="imgSelected" src="../assets/images/tv/disney_70canales.webp"/></a>
                        </div>
                        <div *ngIf="chatMessage[5][i] == 'One ilimitada 1GB'" class="tv_section tvOneOption">
                          <a (click)="selectTV('disney_prime_70canales_img')"><img id='disney_prime_70canales_img' class="imgSelected" src="../assets/images/tv/disney_prime_70canales.webp"/></a>
                        </div>
                        -->
                        <div class="chatOptionsIA">
                          <!--<button class="quieroSaberMas" (click)="addChat('Quiero saber más acerca de la tarifa '+tarifaCard, $event)">Quiero saber más</button>-->
                          <button class="quieroSaberMas" (click)="iWantToKnowMore(tarifaCard, $event)">Quiero saber más sobre esta tarifa</button>
                          <!--<button (click)="goToLink('https://vodafone.estufibra.com/ofertas/?utm_campaign=0101021110&gad_source=1&gclid=EAIaIQobChMItOLYiNDzhAMVoZRoCR19bwXgEAAYAiAAEgJBg_D_BwE')">Lo quiero</button>-->
                          <!--<button (click)="addChat(tarifaCard, $event)">Lo quiero</button>-->
                          <button (click)="iWantIt(tarifaCard, chatMessage[9])" [hidden]="chatMessage[6]">Lo quiero</button>
                          <div *ngIf="chatMessage[7] && chatMessage[7].length > 0"><span>{{chatMessage[7][i]}}</span><small>€/mes</small></div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
              </div>              
              <div class="chatOptionsIA">
                <button class="quieroSaberMas" (click)="callOrquestador('__process__reset__otros',false, true)">Quiero preguntarte sobre otros temas</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Seccion donde escribe el usuario -->
  <div class="footerWatson fixed-bottom">
    <div class="inputWatson" [ngClass]="finalOfChat ? 'disabled' : ''">
      <input type="text" placeholder="¿En qué puedo ayudarte?" [(ngModel)]="chatInputValue" autofocus [disabled]="finalOfChat">
      <div class="sendButtonArrow" (click)="callOrquestador(chatInputValue, true, false)"></div>
    </div>
  </div>
</div>

<div class="popup-container" *ngIf="instance === '4' && !askForPhone">
  <div class="popup-content">
    <div class="popup-header">
      <div class="div-header"><p><img src="../assets//images/chat-bot.png"/> ¡No pierdas el hilo!</p></div>
      <div class="div-close" (click)="closePopup()">X</div>
    </div>
    <div class="popup-form">
      <p class="popup_text">Hola 👋 antes de empezar y para que no te pierdas nada, déjanos tu teléfono y te llamaremos para retomar esta conversación si lo necesitas.</p>
      <form>
        <input type="tel" placeholder="Tu número de teléfono" [(ngModel)]="userPhone" (input)="validatePhone()" name="phoneNumber">
        <span class="errorPhone" [hidden]="errorPhone">Telefono no válido</span>
        <div class="button-group">
          <button class="vfBtn" type="button" (click)="savePhone()" [disabled]="disabledSendBtn">Aceptar</button>
        </div>
        <div class="checkbox-group">
          <label>
            <input type="checkbox" [(ngModel)]="acceptPrivacyPolicy" name="acceptPrivacyPolicy" (change)="checkButton()"> Acepto la &nbsp;
              <a id="dsa-sm-privacidad"
                href="https://www.vodafone.es/c/conocenos/es/vodafone-espana/quienes-somos/legal-y-regulatorio/politica-de-privacidad-y-cookies/"
                target="_parent"
                class="measurec2c cboxElement"
                title="Vodafone One"
                sec_uuid="096f8cd23e21"
                sec_uuid_js:web.vodafone.es="faf2fa6903ba">
                política de privacidad
              </a>
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="acceptCommercialCommunications" name="acceptCommercialCommunications"> Acepto recibir comunicaciones comerciales de Vodafone (opcional)
          </label>
        </div>
        <div class="button-group">
          <button class="vfBtn-white" type="button" (click)="setInstance('2'); askForPhone= true">Quizá en otro momento</button>
        </div>
      </form>
    </div>
  </div>
</div>