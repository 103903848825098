export const environment = {
  production: true,
  apiurlAuth: "Basic YXBpa2V5OkQ2XzJfQzIzWmZhNGVPVGJiSEpxQVZWLThoREJHalczZlFqbURVY0U0YlI0",
  apiurlSessionId: "https://api.eu-de.assistant.watson.cloud.ibm.com/instances/1f7ddba4-67cd-4975-b2ef-43400f33685c/v2/assistants/0f254881-baa3-4aa1-805b-a2f9d3976881/sessions?version=2023-06-15",
  apiurlConversationPartInitial: "https://api.eu-de.assistant.watson.cloud.ibm.com/instances/1f7ddba4-67cd-4975-b2ef-43400f33685c/v2/assistants/0f254881-baa3-4aa1-805b-a2f9d3976881/sessions/",
  apiurlConversationPartFinal: "/message?version=2023-06-15",
  apiOrquestadorPRO: 'https://api.vf-magica-prod-ks-cluster-12864a6038e11ed5483f415a49dd6c73-0000.eu-de.containers.appdomain.cloud/sse?prompt=',
  apiOrquestadorDEV: 'https://api.vf-magica-prod-ks-cluster-12864a6038e11ed5483f415a49dd6c73-0000.eu-de.containers.appdomain.cloud/sse?prompt=',
  apiOrquestadorLocal: 'http://localhost:8000/sse?prompt=',
  apiFeedbackDEV: 'https:// 172.21.197.25/update?',
  apiFeedbackPRO: 'https:// 172.21.197.25/update?',
  apiBBDDDEV: 'https:// 172.21.197.25/insert'
};
